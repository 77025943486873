<template>
    <client-page>
        
        <sub-visual
        sh="제품 및 솔루션"
        sc="에프원시큐리티는 다방면으로 파트너의 정보를 보호합니다"
        tabActive="F1 WMDS"
        icon="/images/sub/visual/sv3-icon.svg"
        bg="/images/sub/visual/sv3.png">
        </sub-visual>
        
        <section class="sub-section-sm sub-section-sm--first">
            <v-container>
                <div class="mb-28 mb-lg-54">
                    <h3 class="sub-tit--lg text-center mb-12 mb-lg-24" data-aos="fade-up">
                        악성코드 유포·경유 웹사이트(홈페이지)란?
                    </h3>
                    <p class="page-text--lg color-gray text-center">
                        이용자가 브라우저로 접속하면 악성코드에 감염될 수 있는 대단히 위험한  웹사이트 입니다. <br>
                        최근 사회적 문제로 이슈화된 랜섬웨어 같은 악성코드 역시 이같은 웹사이트에 접속하는 것만으로 감염됩니다.
                    </p>
                </div>

                <div class="w-100 pa-30 pb-md-40 mb-40 mb-lg-80 bg-grey-full">
                    <v-img class="d-block mx-auto" max-width="598" src="/images/sub/products/wmds/s1-img1.png"></v-img>
                </div>

                <h4 class="line-txt" data-aos="fade-up">
                    우리 기업의 웹사이트에서 악성코드가 유포·경유되면?
                </h4>
                <v-row class="mx-lg-n44">
                    <v-col cols="12" lg="4" class="px-lg-44 mb-20 mb-lg-0">
                        <div class="process-3dep1 w-100 h-100" data-aos="fade-right" data-aos-delay="100">
                            <div class="radius-lg bg-grey h-100">
                                <v-img class="d-block w-100" max-width="1024" src="/images/sub/products/wmds/s1-img2-1.png"></v-img>
                                <div class="pa-16 py-md-46">
                                    <p class="page-text-sm color-grey-49 text-center">
                                        웹사이트가 해킹 당하는 순간에는 <br class="d-none d-lg-block">
                                        피해자이지만 악성코드를 <br class="d-none d-lg-block">
                                        <span class="font-weight-bold color-point">유포 • 경유시키는 순간 가해자</span>가 <br class="d-none d-lg-block">
                                        되어버립니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="4" class="px-lg-44 mb-20 mb-lg-0">
                        <div class="process-3dep2 w-100 h-100" data-aos="fade-right" data-aos-delay="400">
                            <div class="radius-lg bg-grey h-100">
                                <v-img class="d-block w-100" max-width="1024" src="/images/sub/products/wmds/s1-img2-2.png"></v-img>
                                <div class="pa-16 py-md-46">
                                    <p class="page-text-sm color-grey-49 text-center">
                                        정보통신망법의 적용 대상인 <br class="d-none d-lg-block">
                                        사업체는 법에서 요구하는 보안 <br class="d-none d-lg-block">
                                        조치가 미흡했다는 이유로 정부 <br class="d-none d-lg-block">
                                        단속 및 <span class="font-weight-bold color-point">과태료 / 과징금 부과대상</span>이 <br class="d-none d-lg-block">
                                        될 수 있습니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="4" class="px-lg-44">
                        <div class="process-3dep3 w-100 h-100" data-aos="fade-right" data-aos-delay="700">
                            <div class="radius-lg bg-grey h-100 process-3dep3">
                                <v-img class="d-block w-100" max-width="1024" src="/images/sub/products/wmds/s1-img2-3.png"></v-img>
                                <div class="pa-16 py-md-46">
                                    <p class="page-text-sm color-grey-49 text-center">
                                        악성코드에 감염된 사용자들로부터 <br class="d-none d-lg-block">
                                        <span class="font-weight-bold color-point">집단소송분쟁</span>에 휘말릴 수도 있어 <br class="d-none d-lg-block">
                                        기관 / 기업 이미지에 심각한 <br class="d-none d-lg-block">
                                        타격을 줍니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <section class="sub-section-sm">
            <v-container>
                <h3 class="sub-tit--md text-center mb-26 mb-lg-42" data-aos="fade-up">
                    F1-WMDS <br>
                    Web Malware Detection System란?
                </h3>

                <div class="bg-grey-full pa-30 pa-md-70 mb-30 mb-lg-70">
                    <v-img class="d-block mx-auto" max-width="820" src="/images/sub/products/wmds/s2-img1.png"></v-img>
                </div>

                <div class="bg-white border-light radius-md">
                    <div class="border-bottom border-color-light pa-16 pa-md-0">
                        <v-img class="tb mx-auto" max-width="1200" src="/images/sub/products/wmds/s2-img2.png"></v-img>
                        <v-img class="mo mx-auto" max-width="561" src="/images/sub/products/wmds/s2-img2-mo.png"></v-img>
                    </div>
                    <v-row no-gutters class="bg-grey">
                        <v-col cols="12" md="6" class="border-right border-color-light d-flex align-center pa-16 px-md-50 py-lg-20">
                            <v-img class="d-block mr-10 mr-lg-20" max-width="90" src="/images/sub/products/wmds/s2-icon1-1.png"></v-img>
                            <div>
                                <p class="page-text-sm color-grey-49 line-height-12">
                                    <span class="font-weight-bold d-inline-block mb-2 mb-lg-4">공격자(해커)</span> <br>
                                    의도적 공격으로 보안 취약점을 이용한 <br class="d-none d-lg-block">
                                    악성코드 삽입
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex align-center pa-16 px-md-50 py-lg-20">
                            <v-img class="d-block mr-10 mr-lg-20" max-width="90" src="/images/sub/products/wmds/s2-icon1-2.png"></v-img>
                            <div>
                                <p class="page-text-sm color-grey-49 line-height-12">
                                    <span class="font-weight-bold d-inline-block mb-2 mb-lg-4">협력사</span> <br>
                                    비의도적인 공격으로 협력사 등에서 제작한 컨텐츠에 <br class="d-none d-lg-block">
                                    이미 악성코드가 삽입된 상태로 업로드
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </section>

        <section class="sub-section">
            <v-container>
                <h3 class="sub-tit--md text-center mb-26 mb-lg-42" data-aos="fade-up">
                    악성코드 경유·유포 사이트를 통한 <br class="d-none d-lg-block">
                    사고 탐지에 최적화되어 있습니다. 
                </h3>

                <h4 class="line-txt" data-aos="fade-up">
                    특장점 요약
                </h4>

                <v-row class="mb-40 mb-lg-86">
                    <v-col cols="6" lg="4">
                        <div class="radius-sm card pa-16 py-md-50 px-md-34 h-100 border" data-aos="fade-right" data-aos-delay="100">
                            <p class="font-size-14 font-size-lg-20 line-height-1 font-weight-bold color-point mb-8 mb-lg-18">
                                01
                            </p>
                            <h6 class="page-text-lg font-weight-bold mb-4 mb-lg-10">
                                외부링크 추적
                            </h6>
                            <p class="page-text-sm color-grey-49">
                                외부링크를 인식하고 추적하여 <br class="d-none d-lg-block">
                                유포지까지 경로 추적
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="6" lg="4">
                        <div class="radius-sm card pa-16 py-md-50 px-md-34 h-100 border" data-aos="fade-right" data-aos-delay="400">
                            <p class="font-size-14 font-size-lg-20 line-height-1 font-weight-bold color-point mb-8 mb-lg-18">
                                02
                            </p>
                            <h6 class="page-text-lg font-weight-bold mb-4 mb-lg-10">
                                최신 DB 패턴 적용
                            </h6>
                            <p class="page-text-sm color-grey-49">
                                악성코드 유포지/경유지 DB 패턴 최신화 제공 <br>
                                (한국인터넷진흥원, 자체분석인력 보유등)
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="6" lg="4">
                        <div class="radius-sm card pa-16 py-md-50 px-md-34 h-100 border" data-aos="fade-right" data-aos-delay="700">
                            <p class="font-size-14 font-size-lg-20 line-height-1 font-weight-bold color-point mb-8 mb-lg-18">
                                03
                            </p>
                            <h6 class="page-text-lg font-weight-bold mb-4 mb-lg-10">
                                운영 편리성
                            </h6>
                            <p class="page-text-sm color-grey-49">
                                점검대상 웹사이트 Depth와 페이지수 설정 <br class="d-none d-lg-block">
                                가능 등 편리한 운영환경 제공
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="6" lg="4">
                        <div class="radius-sm card pa-16 py-md-50 px-md-34 h-100 border" data-aos="fade-right" data-aos-delay="1000">
                            <p class="font-size-14 font-size-lg-20 line-height-1 font-weight-bold color-point mb-8 mb-lg-18">
                                04
                            </p>
                            <h6 class="page-text-lg font-weight-bold mb-4 mb-lg-10">
                                난독화 처리
                            </h6>
                            <p class="page-text-sm color-grey-49">
                                Javascript 난독화 처리 기술을 이용한 <br class="d-none d-lg-block">
                                은닉된 유포코드 탐지
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="6" lg="4">
                        <div class="radius-sm card pa-16 py-md-50 px-md-34 h-100 border" data-aos="fade-right" data-aos-delay="1300">
                            <p class="font-size-14 font-size-lg-20 line-height-1 font-weight-bold color-point mb-8 mb-lg-18">
                                05
                            </p>
                            <h6 class="page-text-lg font-weight-bold mb-4 mb-lg-10">
                                웹 서비스 안정성 유지
                            </h6>
                            <p class="page-text-sm color-grey-49">
                                365X24시간 악성코드 유포 <br class="d-none d-lg-block">
                                삽입 탐지
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="6" lg="4">
                        <div class="radius-sm card pa-16 py-md-50 px-md-34 h-100 border" data-aos="fade-right" data-aos-delay="1600">
                            <p class="font-size-14 font-size-lg-20 line-height-1 font-weight-bold color-point mb-8 mb-lg-18">
                                06
                            </p>
                            <h6 class="page-text-lg font-weight-bold mb-4 mb-lg-10">
                                간편한 등록
                            </h6>
                            <p class="page-text-sm color-grey-49">
                                Non-Agent 로 도메인만 등록하면 <br class="d-none d-lg-block">
                                사용이 가능
                            </p>
                        </div>
                    </v-col>
                </v-row>

                <h4 class="line-txt" data-aos="fade-up">
                    주요 대상고객
                </h4>

                <v-row no-gutters class="radius-lg">
                    <v-col cols="12" lg="6" class="pa-16 py-lg-48 bg-img d-lg-flex align-center justify-center" style="background-image: url(/images/sub/products/wmds/s3-img1-1.png);">
                        <div style="w-auto">
                            <h6 class="page-text-lg color-white mb-4 mb-lg-32">
                                방문 고객 보호 및 신뢰성 유지
                            </h6>
                            <p class="page-text-sm color-white font-weight-bold">
                                공공기관
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" class="pa-16 py-lg-48 pl-lg-60 bg-img" style="background-image: url(/images/sub/products/wmds/s3-img1-2.png);">
                        <h6 class="page-text-lg color-gery-57 mb-4 mb-lg-8">
                            보안관제 업체, 웹호스팅 업체, IDC, <br class="d-none d-lg-block">
                            그룹/계열사 보안관리조직, CERT조직 등
                        </h6>
                        <p class="page-text-sm color-point font-weight-bold">
                            대규모 웹사이트 보안관리 조직
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section class="sub-section-sm">
            <v-container>
                <div class="section-title mb-32 mb-lg-56">
                    <h3 class="sub-tit--md text-center mb-12 mb-lg-24" data-aos="fade-up">
                        F1-WMDS 솔루션 <br>
                        (Web Malware Detection System)
                    </h3>
                    <p class="page-text-lg text-center">
                        웹사이트에 멀웨어를 배포하는 코드가 삽입되는지를 주기적으로 검사하고 악성코드가 배포되기 전에 <br class="d-none d-lg-block">
                        미리 탐지하여 시스템관리자 또는 관제시스템에 알려 선 조치를 취하기 위한 시스템 입니다.
                    </p>
                </div>
                <div class="s2-box border-light bg-white radius-md">
                    <v-row no-gutters>
                        <v-col cols="12" lg="6" class="border-right border-color-light">
                            <p class="py-10 page-text-sm font-weight-bold color-point text-center border-bottom border-color-light bg-grey">
                                침해사고 탐지/대응강화
                            </p>
                            <div class="pa-16 py-md-24">
                                <p class="color-grey-49 page-text-sm text-center">
                                   신규/변경 되는 웹서비스 Contents에 대한 <br class="d-none d-lg-block">
                                   주기적인 점검으로 사고예방 및 대응
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <p class="py-10 page-text-sm font-weight-bold color-point text-center border-bottom border-color-light bg-grey">
                                웹서비스 연속성 보장
                            </p>
                            <div class="pa-16 py-md-24">
                                <p class="color-grey-49 page-text-sm text-center">
                                   Non-Agent 구현 및 네트워크 구성에 영향을 <br class="d-none d-lg-block">
                                   주지 않음으로써 웹서비스 연속성 보장
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                    <p class="w-100 bg-point color-white text-center font-weight-bold page-text-lg pa-14 border-top border-bottom border-color-light">
                        웹 기반 악성코드 유포공격 대응 전용 탐지 솔루션
                    </p>
                    <v-row no-gutters>
                        <v-col cols="12" lg="4" class="border-right border-color-light">
                            <div class="bg-grey py-10">
                                <v-img class="mx-auto d-block" max-width="48" src="/images/sub/products/ws/arrow-bottom.png"></v-img>
                            </div>
                            <div class="pa-16 py-md-50 border-top border-color-light">
                                <v-img class="mx-auto d-block mb-8 mb-lg-20" max-width="69" src="/images/sub/products/wmds/s4-icon1-1.png"></v-img>
                                <p class="page-text-lg line-height-1 font-weight-bold text-center">편리한 사용</p>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="4" class="border-right border-color-light">
                            <div class="bg-grey py-10 d-none d-lg-block">
                                <v-img class="mx-auto d-block" max-width="48" src="/images/sub/products/ws/arrow-bottom.png"></v-img>
                            </div>
                            <div class="pa-16 py-md-50 border-top border-color-light">
                                <v-img class="mx-auto d-block mb-8 mb-lg-20" max-width="69" src="/images/sub/products/wmds/s4-icon1-2.png"></v-img>
                                <p class="page-text-lg line-height-1 font-weight-bold text-center">웹서버 보안성 강화</p>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <div class="bg-grey py-10 d-none d-lg-block">
                                <v-img class="mx-auto d-block" max-width="48" src="/images/sub/products/ws/arrow-bottom.png"></v-img>
                            </div>
                            <div class="pa-16 py-md-50 border-top border-color-light">
                                <v-img class="mx-auto d-block mb-8 mb-lg-20" max-width="69" src="/images/sub/products/wmds/s4-icon1-3.png"></v-img>
                                <p class="page-text-lg line-height-1 font-weight-bold text-center">간편한 운영</p>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </section>
        <section class="sub-section-sm sub-section--last">
            <v-container>
                <h3 class="sub-tit--md text-center mb-26 mb-lg-42" data-aos="fade-up">
                    악성코드 경유·유포 사이트를 통한 <br>
                    사고 탐지에 최적화되어 있습니다.
                </h3>
                <h4 class="line-txt" data-aos="fade-up">
                    WMDS 특장점
                </h4>
                <div class="radius-lg bg-grey mb-40 mb-lg-80 tb">
                     <v-img class="w-100 d-block" max-width="1200" src="/images/sub/products/wmds/s5-img1.png"></v-img>
                </div>
                <div class="mb-40 mo">
                    <v-img class="mx-auto d-block" max-width="400" src="/images/sub/products/wmds/s5-img1-mo.png"></v-img>
                </div>
                <h4 class="line-txt">
                    F1-WMDS 기대효과
                </h4>
                <v-img class="mx-auto d-block" max-width="1197" src="/images/sub/products/wmds/s5-img2.png"></v-img>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>

<style lang="scss" scoped>
.process-3dep1, 
.process-3dep2{
    position: relative;
}

.process-3dep1::after, 
.process-3dep2::after{
    content: "";
    display: block;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 4px solid #f4f7fb;
    background-color: #e4eefc;
    background-image: url(/images/sub/products/wmds/process-arrow.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    top: 50%;
    right: -72px;
    transform: translate(0, -50%);
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .card{
        min-height: 246px;
    }
}
@media (min-width:1200px){
}

    /* Title */
    .tit-txt-wrap{
        margin-bottom: 40px;
    }
    .tit-wrap{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }
    .tit-txt-wrap .tit-wrap{
        margin-bottom: 10px;
    }
    .tit{
        width: 100%;
        font-size: 2.6rem;
        font-weight: 500;
        line-height: 1.2;
        word-break: keep-all;
    }
    .tit.font-notoserif{
        line-height: 1.4;
    }
    .tit--xs{
        font-size: 1.8rem;
        font-weight: 600;
    }
    .tit--sm{
        font-size: 2rem;
    }
    .tit--lg{
        font-size: 3.2rem;
        line-height: 40px;
        font-weight: 700;
        margin-bottom: 38px;
    }
    .tit-txt{
        font-size: 1.4rem;
    }


    @media (min-width:576px){
    }
    @media (min-width:768px){
        .tit{
            font-size: 3rem;
        }
        .tit--xs{
            font-size: 1.8rem;
        }
        .tit--lg{
            font-size: 4rem;
            line-height: 50px;
        }
    }
    @media (min-width:1024px){
        .tit-txt-wrap{
            margin-bottom: 60px;
        }
        .tit-wrap{
            margin-bottom: 60px;
        }
        .tit-txt-wrap .tit-wrap{
            margin-bottom: 20px;
        }
        .tit{
            font-size: 3.6rem;
        }
        .tit--xs{
            font-size: 2rem;
        }
        .tit--sm{
            font-size: 2.4rem;
        }
        .tit--lg{
            font-size: 5.2rem;
            line-height: 66px;
            margin-bottom: 72px
        }
        .tit-txt{
            font-size: 1.6rem;
        }
    }
    @media (min-width:1200px){
    }
/* Page Text */
.page-text{
    font-size: 1.6rem;
    line-height: 24px;
}
.page-text-sm{
    font-size: 1.4rem;
    line-height: 20px;
}
.page-text-lg{
    font-size: 1.6rem;
    line-height: 24px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .page-text{
        font-size: 2rem;
        line-height: 30px;
    }
    .page-text-sm{
        font-size: 1.8rem;
        line-height: 28px;
    }
    .page-text-lg{
        font-size: 2.2rem;
        line-height: 30px;
    }
}
@media (min-width:1200px){
}
</style>